.btn_text{
    white-space: nowrap;
    font-weight: 800;
    color: #4c7b9a !important;
}

.footer_modal {
    font-size: 1.6rem;
    line-height: 1.4;
    color: #2E303B !important;
    border-bottom: none;
    text-align: left;

    &:hover {
        color: #2E303B !important;
        text-decoration: underline;
        transform: none !important;
    }
}

.btn_style {
    padding: 0 !important;
    justify-content: start !important;

    &:hover {
        transform: none !important;
        box-shadow: none !important;
    }
}
