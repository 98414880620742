@use 'styles/globals/mixins/font';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.imageHeading{
    align-items: center;
    align-content: center;
    padding-top: 6rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem 2rem 0 2rem;
    }
}

.headingLink{
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover{
        text-decoration: none;
    }
}


.navigateBtn{
    background-color: transparent;
    line-height: 1;
    display: flex;
    align-items: center;

    & svg{
        fill: var(--indigo)
    }

    &:disabled{
        opacity: .25;
        cursor: not-allowed;
    }
}

.leftBtn{
    left: -20px
}

.rightBtn{
    right: -20px;
}

.item{
    border-top: 2rem solid var(--white);
    border-bottom: 2px solid var(--dove);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    &:last-of-type{
        border-bottom: none;
    }
}

.image{
    margin: 0 6rem 4rem 0;
    
    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0;
        object-fit: contain;
        width: 100%;
    }
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--mist);
    padding: 6rem 6rem 2rem 6rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
       padding: 4rem 2rem;
    }
}

.contentHeading{
    display: flex;
    flex-direction: column;
    margin: 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        text-align: center;
    }
}

.priceBlock{
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.price{
    font-size: 3rem !important;
    margin-right: 1rem;
}

.atcBlock{
    width: 100%;
    display: flex;
    padding: 2rem 0;
}

.options{
    width: 100%;
    @extend %optionsBlock;
    margin: 2rem 0;
}

// slider
.sliderBlock{
    padding: 2rem 0 0 0;
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    flex-wrap: wrap;
    max-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--mist);
    box-shadow: var(--box-shadow-sm);
    padding: 1rem;
    margin: 0 0 1rem 0;

    // &::after {
    //     content: "";
    //     flex: auto;
    //     max-width: 48%;
    // }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        &::after {
            display: none;
        }
    }

}


.expandedCntr{
    // max-height: 800px;
    overflow-y: auto;
    // padding: 0 0 3rem 0;
}

// Addon Pod Styles

.legend{
    width: 100%;
    display: flex;
    box-shadow: var(--box-shadow-sm);
    padding: 2rem;
    line-height: 1.3;
    background-color: var(--white);
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 2rem;
    border-bottom: 1px solid var(--dove);

    &:first-of-type{
        margin-top: 0;
    }
}

.optionPodGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 100%;
    margin: 0;
    flex-wrap: wrap;
}

.noThanks{
    justify-content: center;
}

.podBlock{
    position: relative;
    display: flex;
    min-height: 280px;
    width: 50%;
    cursor: pointer;
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: .5rem solid var(--mist);

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        width: 100%;
        flex: none;
        max-width: unset;
        max-width: 50%;
        flex: 1;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
       height: auto;
       width: 100%;
       max-width: none;
    }

}

.podSelected{
    border-color: var(--terracotta) !important;
    box-shadow: var(--box-shadow);
}

.podBlockInner{
    background-color: var(--white);
    flex-direction: column;
    align-items: flex-start;
    transition: box-shadow 0.3s ease-in-out;
    border-radius: var(--border-radius);
    border: 3px solid transparent;
    margin: 0;
    height: 100%;
    display: flex;
    width: 100%;
    padding: 2rem;

    &:hover{
        box-shadow: var(--box-shadow);

        @media print{
            box-shadow: none;
        }
    }
}


[data-tracking="addon_option_cleaning_products"]{
    & .podBlock{
        &::before{
            display: none;
        }
    }
}



   

.podImage{
    object-fit: contain;
    // width: 100%;
    flex: 1 0 0%;
    max-width: 200px;
    max-height: 200px;
    height: auto;
}

.podContent{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.podTitle{
    @include font.dynamic-font(12, 14);
    width: 100%;
    line-height: 1.2;
    padding-top: 2rem;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
}

.podPrice{
    display: flex;
    width: 100%;
    @include font.dynamic-font(14, 16);
    align-items: baseline;
    margin-top: .5rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: block;
    }
}

.podLink{
    width: 100%;
    height: 100%;
    border-bottom: none;
    max-height: unset;
    background-color: transparent;
}

.from{
    color: #aaa
}

.podSwatchBlock{
    position: absolute;
    top: 4rem;
    right: 4rem;
}

.podSwatch{
    width: 70px;
    height: 70px;
    border-radius: 99rem;
    margin-right: 1rem;
}

.podSwatchLabel{
    font-size: 1.2rem;
    text-align: center
}

.podControl{
    opacity: 0;
    visibility: hidden;
    position: absolute
}


.control{
    @include font.dynamic-font(14, 16);
    font-weight: 800;

    & svg{
        stroke: var(--link_color);
    }
}

.loading{
    text-decoration: none !important;
    border-bottom: none;

    & [class*="Loader_error"]{
        &::after{
            font-size: 4rem;
            color: var(--charcoal);
            top: 12px;
        }
    }

    & svg{
        width: 20px;
        height: 20px;
    }

    & svg, svg path{
        fill: var(--charcoal) !important
    }
}


.podSetImage{
    width: 100%;
    height: auto;
    object-fit: contain;
}



.covers{
    width: 100%;


    & .podBlock{
        width: 100% !important;
    }

    // & .podBlockInner{
    //     padding: 2rem;
    //     margin: 0;
    //     height: auto;
    // }

    & .podImage{
        align-self: center;
    }

    &::after{
        display: none;
    }

    .podTitle{
        padding: 0;
    }

    .podImageWrapper{
        margin-bottom: 0rem;
        // overflow: hidden;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        flex: 1 0 auto;

        &:has(img + img){
            & .podImage::before{
                content: "+";
                font-size: 4rem;
                font-weight: 800;
                position: absolute;
                width: 20px;
                height: 30px;
                top: 50%;
                right: 50%;
                margin-right: -10px;
            }

            &::after {
                content: "";
                flex: auto;
            }
        }
    }
}

.podImageWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    & > *{
        flex-shrink: 0;
    }
}

