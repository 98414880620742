@use 'styles/globals/breakpoints';


.block{
    display: flex;
    padding: 0 1rem;
    position: relative;
    flex-direction: column;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0;
    }
}

.unloaded{
    & img{
        object-fit: cover;
        height: 350px;
    }
}

.linkBlock, .linkBlock3PerRow{
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 100% !important;
    max-width: 100% !important;


    @media (min-width: 480px) {
        min-width: calc(66.66%) !important;
        max-width: calc(66.66%) !important;
    }

    @media (min-width: 768px) {
        min-width: calc(50%) !important;
        max-width: calc(50%) !important;
    }

    @media (min-width: 850px) {
        min-width: calc(40%) !important;
        max-width: calc(40%) !important;
    }

    @media (min-width: 1280px) {
        min-width: calc(25%) !important;
        max-width: calc(25%) !important;
    }
}
.linkBlock3PerRow {
    .block {
        padding-left: 0;
    }
    @media (min-width: 1280px) {
            max-width: calc(33.3%) !important;
        }
}

.img,.img3PerRow {
    object-fit: contain;
    width: 100%;
    height: auto;
    min-height: 350px;
    max-width: 350px;
    padding: 0 1rem;
    // position: absolute;
}
.img3PerRow{
    @media (min-width: 1280px) {
        max-width: 490px;
    }
}
.title{
    margin: 1rem 0;
    height: 55px;
}


