@use './functions' as *;


$default-cursor: pointer;
$border-off: none;

$standard-width: 2000px;
$standard-padding: 2rem;
$standard-column-width: 100%;
$header-padding: 4vw;

$hr-border: #ccc;
$standard-border: 1px solid transparent;

$border-radius: 4px;
$button-border-radius: 2px;

// colors
$primary-color: #2E303B;

$charcoal: #2E303B;
$grey: #999;
$dove: #F0F0F0;
$mist: #f9f9f9;
$white: #fff;

$indigo: #353E5D;
$ocean: #4c7b9a;
$ice: #dfe9ef;
$ice50: #f0f4f7;
$ice25: #f8fafb;

$green: #6fa33f;
$sage: #b0d493;
$pistachio: #e0eed5;
$pistachio50: #eff6e9;
$pistachio25: #f7fbf5;

$terracotta: #db613a;
$sunset: #e5a778;
$teak: #fbdfca;
$teak50: #ffefe3;
$teak25: #fff7f1;
$white: #ffffff;
$holidayred: #BC0000;

$link_color: #4c7b9a;

$vds-colors: (
  "charcoal":   $charcoal,
  "dove":       $dove,
  "mist":       $mist,
  "indigo":     $indigo,
  "ocean":      $ocean,
  "ice":        $ice,
  "green":      $green,
  "sage":       $sage,
  "pistachio":  $pistachio,
  "terracotta": $terracotta,
  "sunset":     $sunset,
  "teak":       $teak,
  "white":      $white,
  "holidayred": $holidayred,
) !default;

// Typography
$font-stack: var(--font-montserrat), Helvetica, Arial, sans-serif !default;
$font-stack-serif: var(--font-theSeasons), "the-seasons", Georgia, Times, serif !default;

$letter-spacing: 0.05rem;
$font-size-base: 1.6rem;
$font-size-mobile: $font-size-base - 0.4; // ~1.6rem

$font-size-h1: $font-size-base + 3; 
$font-size-h2: $font-size-base + 2.2; 
$font-size-h3: $font-size-base + 1; 
$font-size-h4: $font-size-base + 0.7; 
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-base - 0.2; 

$headings-font-family: inherit !default;
$headings-font-weight: bold !default;
$headings-line-height: 1 !default;
$headings-color: inherit !default;


// Grid
$grid-columns: 11 !default;

$screen-xs: 400px; // mobile landscape
$screen-sm: 568px; // tablet portrait
$screen-md: 850px; // tablet landscape
$screen-lg: 1024px; // Desktop
$screen-xl: 1200px; // Large Desktop & HD TV
$screen-xxl: 1500px; // HD TV

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$enable-grid-classes:       true !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 30px !default;


// buttons
$btn-font-weight: lighter;


$box-shadow: 0 .5rem 1rem rgba(#000, .15) !default;
$box-shadow-sm: 0 .125rem .25rem rgba(0,0,0,.075) !important;
$box-shadow-lg: 0 1rem 3rem rgba(0,0,0,.175) !important;

// animations
$easeInOutBack: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
