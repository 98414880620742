@use 'styles/globals/breakpoints';


// .textBlock{
//     @extend %textBlock
// }

.container {
    margin: auto;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        flex-direction: column;
    }
}

.accordionImage img {
    width: 100%;
    height: auto;
}

.text p {
    width: 100%;
}

