@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100vw;
        flex-direction: column;
        padding: 0 2rem
    }
}

.title{
    text-align: center;
}

.column{
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 0;
    }
}

.contentWrapper{
    width: 50%;
    display: flex;
    flex-direction: row;
    //padding-top: 25%;
    padding-left: 4rem;
    order: 1;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding-top: 0;
        order: 4;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        width: 100%;
        padding: 2rem !important;
    }
}

.linkColumn {
    width: 100%;
    height: auto;
}

.imageCntr{
    width: 50%;
    flex: 1 0 50%;
    display: flex;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
    }
}

.image {
    object-fit: cover;
    width: 100%;
    height: auto;
    align-self: flex-start;
    object-position: top;
}

.content{
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        order: 2;
        padding-top: 4rem;
    }
}

.nosto{
    padding: 2rem 0 0 0;
    width: 100%;

    & [class*="PodSlider_block"]{
        padding: 0 !important;
    }


    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0
    }
}

.heading{
    color: inherit;

    &:hover{
        text-decoration: none;
    }
}

