@use '/styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin-left: -2vw;
}


.col{
    display: flex;

    &__item{
        background-color: var(--white);

        @media screen and (max-width: breakpoints.$screen-lg){
            padding: 0 2rem 4rem 2rem;
        }

        @media screen and (max-width: breakpoints.$screen-md){
            order: 2;
        }
    }

    &__summary{
        background-color: var(--mist);
        padding: 0 4rem;
        min-height: 800px;

        @media screen and (max-width: breakpoints.$screen-lg){
            padding: 0 2rem 4rem 2rem;
        }

        @media screen and (max-width: breakpoints.$screen-md){
            order: 1;
        }
    }
}

.quickSummary{
    display: flex;
    flex-direction: column;
    background-color: var(--dove);
    margin: 3rem 0 0 0;
    padding: 2rem;
}

.sectionHeader{
    padding: 6rem 0 4rem 0;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;

    &:hover{
        .cartLink{
            opacity: 1;
        }
    }

    @media screen and (max-width: breakpoints.$screen-lg){
        text-align: center;
    }
}

.cartHeader{
    @include font.dynamic-font(22, 33);
    @extend %h2;
    margin-bottom: 0;
    line-height: 1;
}

.cartHeaderText{
    margin: 0;
}

.summaryHeader{
    @include font.dynamic-font(22, 33);
    @extend %h2;
    text-align: center;
    line-height: 1;
    margin-bottom: 0rem;
}


.cartLink{
    opacity: 0;

    & svg{
        margin-left: .5rem;
        fill: var(--link_color);
    }
}
