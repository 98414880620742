@use 'styles/globals/breakpoints';
@import "styles/globals/placeholders";

.block{
    margin-bottom: 4rem;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        margin-bottom: 2rem;
    }
}

.heading{
    @extend %h1;
    line-height: 1;
    text-align: center;
}


.merchandisingBanner {
    padding: 4rem 0 0 0;
    position: relative;

    &::after{
        content: "";
        position: absolute;
        background-color: var(--ice);
        width: 105%;
        height: 75%;
        left: -2.5%;
        top: 0;
        z-index: -1;

        @media only screen and (max-width: breakpoints.$screen-sm ) {
            display: none;
        }
    }

    & img {
        max-height: 430px;
        max-width: 100%;
        width: 100%;
        min-height: 9rem;
        object-fit: contain;
    }

    & > div {
        max-width: 100% !important;
    }
}

