@use 'styles/globals/breakpoints';


// logo
.block{
    width: 40%;
    flex: 1 0 40%;

    @media only screen and (max-width: breakpoints.$screen-xl) and (min-width: breakpoints.$screen-lg){
        width: 50%
    }

    @media screen and (max-width: breakpoints.$screen-lg) and (orientation: landscape){
        width: 100%;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        width: auto;
        flex: auto;
        max-width: 150px;
    }
}

.link{
    display: block;

    @media screen and (max-width: breakpoints.$screen-sm){
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.logo{
    width: 100%;
    max-width: 300px;
    object-fit: contain;   
    margin-top: -7px; 

    @media screen and (max-width: breakpoints.$screen-sm){
        margin-top: 0;
    }
}
