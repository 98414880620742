@use 'styles/globals/breakpoints';

.slideButton{
    display:none;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        display:block;
        background-color: var(--ice);
        border-radius: var(--button-border-radius);
        padding: 1rem;
        width: 50%;
    }
}

.searchContent{
    display: flex;
    margin-top: 3.2rem;
}

.productTitle{
    font-weight: normal;
    font-size: 5.5rem;
    letter-spacing: 6.48px;
    color: #2E303B;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3.2rem;
    font-family: var(--font-stack-serif) !important;
}

.sideBar{
    max-height: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding-right: 2rem;

    @media only screen and (min-width: breakpoints.$screen-md ) and (max-width: breakpoints.$screen-lg) {
        grid-column-start: 2 !important;
        grid-column-end: 10 !important;
        padding: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        margin: 0 4vw;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        margin: 0
    }
}


.content{
    @media only screen and (max-width: breakpoints.$screen-lg ) {
        grid-column-start: 2 !important;
    }
}

@media only screen and (max-width: breakpoints.$screen-lg ) {
    
    .showSidebarTransform{        
        transform: translateX(0);
        transition: transform 0.3s ease-in;
    }

    .hideSidebarTransform {
        transform: translateX(-100%);
        transition: transform 0.3s ease-out;
      }

    .showSidebarSection{
        position: fixed;
        top: 0;  
        left:0;
        height: 100%;
        z-index: 999999;       
        background: white;
        padding-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
     }
   
}



