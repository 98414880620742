@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


// aux items
.block{
    @extend %flex-full-width;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0 0 5px 0;
    padding: 0;
    flex: 1 0 auto;
    width: 100%;
    height: 20px;

    @media screen and (max-width: breakpoints.$screen-md){
        display: none;
    }
}

.auxItem{
    margin-left: 4rem;
    
    &:first-of-type{
        margin: 0
    }
}

.auxLink{
    @include font.dynamic-font(10, 13);
    display: block;
    line-height: 1.4;
    color: inherit;
}
