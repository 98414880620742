
@use 'styles/globals/breakpoints';

.block{
    @media screen and (max-width: breakpoints.$screen-md){
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center;
        //margin-top: 4rem;

        & > [class*="Card_block"]{
            width: 50%;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
    
            &:nth-child(2n){
                border-right: none;
            }

            &:nth-child(8n) {
                border-bottom: none;
            }
            &:nth-child(7n) {
                border-bottom: none;
            }
        }
    }
}

.card{
    //border-right: 6px solid var(--white;

    // &:last-of-type{
    //     border: none;
    // }

    & img{
        filter: brightness(0);
    }

    @media screen and (max-width: breakpoints.$screen-xl){
        border-right: 1px solid var(--white);
        border-bottom: 1px solid var(--white);
        
    }
}

.linkCntr{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
}

.oceanIcon {
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(188deg) brightness(108%) contrast(100%);
}

.oceanBg {
    background-color: #4C7B9A;
    color: #fff !important;
}

.titleHide {
    display: none;
}
