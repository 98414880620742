@use 'styles/globals/breakpoints';


.block{
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    align-self: left;
    background-color: transparent;
    padding: 1rem 0 1rem 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0;
    outline-color: transparent;
    color: inherit;
    -webkit-appearance: none;

    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

.isImmobile{
    padding: 1rem;
}


.icon{
    width: 24px;
    height: 24px;
}


.text{
    margin-left: 1rem;
    font-size: 1.5rem;
    line-height: 1;

    @media screen and (max-width: breakpoints.$screen-lg){
        display: none;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        // display: block;
        font-size: 1.8rem;
    }
}

