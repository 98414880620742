@import 'styles/globals/variables';

.block{
    margin: 2rem 0;
    background-color: $mist;
    padding: 4rem;
    grid-column-start: 2;
    grid-column-end: 10;
}

.text {
    p {
        margin: 0;
    }
}

.list{
    position: relative;
    margin-bottom: 3rem;
    padding-left: 4rem;

    &::before{
        content: "";
        position: absolute;
        height: 100%;
        border-left: 5px solid $ice;
        left: 0;
    }
}

.item{
    margin-bottom: 1rem;

    p {
        margin: 0 !important;
    }
}

.h3 {
    padding: 0 0 3rem;
}

.btn_text {
    white-space: nowrap;
    color: #fff !important;
}

.buttonContainer {
    max-width: 215px;
}