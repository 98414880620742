@use 'styles/globals/breakpoints';
@use "styles/globals/gridUtils.module.scss" as *;

.block{
	display: flex;
	flex-direction: column;

	@media screen and (max-width: breakpoints.$screen-md){
		flex-direction: column !important;
		flex-wrap: wrap;
	}

	@media screen and (max-width: breakpoints.$screen-sm){
		grid-area: none / 1 / none / 10 !important;
		width: 100%;
	}

}

.noWrap{
	flex-wrap: nowrap !important;
}

