@use 'styles/globals/breakpoints';


.headerFeature {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: var(--white);
    padding: 8rem 0 1rem 0;
}

.headerContent {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.headerTitle {
    font: normal normal normal 5.4rem Didot Display;
    letter-spacing: 0.648rem;
    margin-bottom: 2rem;
}

.headingText {
    grid-column: 3 / 9;
    grid-row: 1;
}

.headingText p {
    margin-top: 2rem;
}


.headingText p:nth-child(even) {
    letter-spacing: 0;
}

.headingContentCntr {
    display: flex;
    flex-direction: column;
    padding-left: 6rem;
    flex: 1 0 50%;
    align-content: center;
    justify-content: center;
    margin-top: 0rem;
}

.imgCntr {
    margin-bottom: 2rem;
    display: flex;
    flex: 1 0 50%;

    & img {
        object-fit: cover !important;
    }
}



.hideBlock {
    height: 0px;
    display: none;

}

.showBlock {
    height: auto;
    display: block;
    // transition: .3s cubic-bezier(0, 1, 0.5, 1);

}
@media only screen and (max-width: breakpoints.$screen-md) {

    .headerFeature {   
        padding: 0rem 0 1rem 0;
    }
    .headerContent {
        display: block;
        margin: 0rem 1rem;
    }
    .headingContentCntr {
        display: block;
        padding: 1rem 3rem 0rem 3rem;
    }
    .headingText p {
        font-size: 1.4rem;
    }
}

