
.recentList{
    width: 100%;
    display: flex;
    list-style-type: none;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 0 0;
}

.recentItem{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    overflow: hidden !important;
    padding: 2rem;

    &:hover{
        text-decoration: none !important;
    }

}

.textBlock{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}


.psedoLink{
    text-decoration: underline;
    color: var(--ocean);
}

.categoryName{
    margin: 0 0 .5rem 0;
    color: #999;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.image {
    object-fit: cover;
    overflow: hidden;
    aspect-ratio: 16/9;
    width: 100%;
    height: 350px !important;

    & img {
        object-fit: cover;
        overflow: hidden;
        aspect-ratio: 16/9;
        width: 100%;
        height: 350px !important;
    }
}

