.block{
    width: 100%;
    position: relative;

    [class*="Picture_fill"]{
        height: auto;
    }
}

.desktopImage {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.mobileImage {
    @media screen and (min-width: 769px) {
        display: none;
    }
}

