
.text{
    & p:last-of-type{
        margin: 0;
    }

    & table{
        width: 100%;
    }

    & li a {
        text-decoration: inherit;
        border-bottom: 1px solid var(--ocean);

        &:hover{
            text-decoration: inherit;
            color: var(--indigo);
        }
    }
}

