@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
    margin: 1rem 0 3rem 0;
    position: relative;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        margin: 1rem 0 0rem 0;  
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0 2rem;
    }
}

.content{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
}

.summary{
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    padding: 2rem;

    &::-webkit-details-marker{
        display: none !important;
    }
}

.toggleControl{
    background-color: var(--mist);
    margin: 0 0 3rem 0;
    line-height: 1;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    list-style: none;
}

.step{
    background-color: var(--ocean);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 1;
    min-width: 40px;
    height: 40px;
    border-radius: 99em;
    margin-right: 2rem;
    padding-left: 4px;
}

.meta{
    font-size: 1.4rem;
    margin-left: 1rem;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
}

.titleCntr{
    display: flex;
    margin-bottom: 0 !important;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.titleBlock{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.title{
    display: flex;
    align-items: center;
    line-height: 1.4;
    text-align: left;
    padding-right: 2rem;
}

.titleText{
    font-style: normal !important;
}

.subTitle{
    font-size: 1.2rem;
    text-transform: capitalize;
    letter-spacing: .1rem;
    position: relative;
    padding: 0 0 0 2rem;
    font-style: italic;
    text-align: left;

    &::before{
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%236fa33f' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 32'%3E%3Cpath d='M41.186 1.67c-1.936-1.937-5.075-1.937-7.011 0l-17.239 17.237-8.472-8.472c-1.935-1.937-5.076-1.937-7.011 0s-1.936 5.075 0 7.011l11.978 11.979c0.968 0.969 2.237 1.452 3.506 1.452s2.538-0.483 3.506-1.452l20.744-20.744c1.937-1.936 1.937-5.075 0.001-7.011z'%3E%3C/path%3E%3C/svg%3E");
        width: 12px;
        height: 12px;
        top: 50%;
        left: 0;
        margin-top: -4px;
    }
}

.toggleIcon{
    width: 25px;
    height: 25px;
    transform: rotate(90deg);
    transition: all 100ms;
}

.iconRotate{
    transform: rotate(45deg);
}

.drawerTitle{
    font-weight: normal;
    font-size: 1.8rem;
}

.showMore {
    max-height: 310px;
    overflow-y: hidden;
    transition: all .1s cubic-bezier(.59,.28,.83,.67);
}

.shownMax{
    overflow-y: auto;
    max-height: 80vh;
    @extend %scrolling-inner;
}

.moreBtn{
    font-weight: 800;
    color: var(--link_color);
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ice25);
    padding: 0 .5rem .5rem .5rem;
    border-radius: var(--border-radius);
    line-height: 1;
    content: "";
    position: relative;

    &::before{
        top: -20px;
        content: "";
        width: 100%;
        height: 25px;
        position: absolute;
        background: rgb(248,250,251);
        background: linear-gradient(0deg, rgba(248,250,251,1) 25%, rgba(248,250,251,0.10569196428571426) 100%);
    }

    & svg{
        fill: var(--link_color);
        transform: rotate(-90deg);
        margin-left: .5rem;
    }
}

.maxBtn{
    & svg{
        transform: rotate(90deg);
    }
}

.learnMoreButton {
    position: absolute;
    top: 0;
    padding: 20px 60px;
    margin-top: 1rem;
    color: white;
    text-transform: none;
}

.closeButton {
    padding: 10px 15px;
    aspect-ratio: 1/1;
    bottom: -4%;
    border-radius: 50%;
    position: absolute;
    text-align: center;

    @media screen and (max-width: breakpoints.$screen-md) {
        bottom: 0;
    }
}

.buttonAndDivider {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1 0 auto;
    background-color: white !important;
}

.drawerContainer {
    background-color: transparent !important;
}

.buttonOpen .learnMoreButton {
    bottom: 0;
    top: unset;
}

@media screen and (max-width: 850px) {
    .blockExpand {
        display:grid;
    }
}

