@use 'styles/globals/breakpoints';

.block{
    display: flex;
    background-color: var(--mist);
    padding: 4rem;
    margin-bottom: 10rem;
}

.ingredients{
    //width: 30%;
    padding-right: 2rem;
}

.directions{
    padding: 0 0 0 3rem;
    
    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 5rem 0 0 0;
    }
}

.ingredientsList{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.directionsList{
    margin: 0 0 0 2rem;
    padding: 0
}

.item{
    margin-bottom: 1.4rem;
}

.itemHeading{
    padding-top: 1.4rem;
}

.columnSize {
    grid-template-columns: 40% 60% !important;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        grid-template-columns: 50% 50% !important;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        grid-template-columns: 100% !important;
    }
}
