
@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
    }
}

.imageCntr{
    //width: 50%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 4rem 0;
        width: 90% !important;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        display: flex;
        justify-content: center;
    }
}

.image{
    width: 100%;
    object-fit: contain;
    height: auto;
}


.contentWrapper{
    //width: 50%;
    order: 1;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 4rem 0;
        width: 80% !important;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        width: 100% !important;
        padding: 2rem !important;
    }
}


.heading{
    width: 100%;
}


.content{
   inset: 0;
   width: 100%;
   height: 100%;
   padding: 2rem;
   display: flex;
   align-items: center;
   align-content: center;
   justify-content: center;
   flex-direction: column;

   @media only screen and (max-width: breakpoints.$screen-md ) {
        position: static;
        padding: 0 !important;
   }
}

.noPadding {
    @media only screen and (max-width: breakpoints.$screen-lg) {
        padding: 2rem !important;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0 !important;
    }
}


.fullBackground{
    position: absolute;
    width: 100vw;
    height: 120%;
    left: -10vw;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none;
    }   
}


.textBlock{
    @extend %textBlock;
    margin-bottom: 0;

    & h1{
        @extend %h1;
    }

    & h2{
        @extend %h2;
    }

    & h3{
        @extend %h3;
    }

    & h4{
        @extend %h4;
    }

    & h5{
        @extend %h5;
    }

    & h6{
        @extend %h6;
    }

    & a > strong{
        display: inline-flex;
        background-color: var(--ocean);
        flex: 0 0 auto;
        color: var(--white);
        font-weight: normal;
        padding: 1.7rem calc(1vw + 17px);
        text-transform: capitalize;    
        border-radius: var(--button-border-radius);
        @include font.dynamic-font(16, 24);
        text-decoration: none;
        width: auto;
        @extend %button-animate;
    }
    
    & li a {
        text-decoration: inherit;
        border-bottom: 1px solid var(--ocean);

        &:hover{
            text-decoration: inherit;
            color: var(--indigo);
        }
    }
}


