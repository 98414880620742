@use 'styles/globals/breakpoints';

.content{
    padding: 5rem 0;
}

.mainHeading{
    text-align: center;
    display: flex;
    flex-direction: column;
}

.headingCategory{
    font-size: initial;
}

.hero{
    object-fit: cover;
    height: auto;
    width: 100%;
}

.title {
    margin: 5rem 0 1rem;
}

.author {
    font-size: 1.8rem;
    margin: 2rem 0 3rem;

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 1.6rem;
    }
}

