@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';

%PayBtn{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: var(--button-border-radius);
    margin-bottom: 2rem;
    height: 57px;
    position: relative;
}


.block{
    display: flex;
    margin: 0;
    padding: 0 0 4rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &_simple{
        margin: 0;
    }
}

.button{
    height: 58px;
}


.cntr{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;

    @media screen and (max-width: breakpoints.$screen-xs){
        max-width: auto;
        width: 100%;
    }
}


.acceptedCards{
    width: 60%;
    margin: 2rem 0 4rem 0;
}

.expressOptionsHeader{
    margin: 6rem 0 2rem 0;
}



