@use 'styles/globals/breakpoints';

.block{
    margin-top: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        display: none;
    }
}

.text{
    font-size: 1.4rem;
}

