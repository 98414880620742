@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    transition-timing-function: cubic-bezier(1,-0.03,.23,1);
    transition: all 1s;
    padding: 1rem 10rem;
    justify-content: center;
    line-height: 1;
    background-color: var(--charcoal);
    color: #ccc;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        padding: 1rem;
        width: 100%;
        min-height: auto;
    }
}

.loading{
    background-color: var(--mist);
}

.promoSuccess{
    background-color: #6ea438;
    color: #fff;
}


.promoMessage {
    white-space: unset;
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
    color: inherit;

    &:hover{
        text-decoration: none;
        color: inherit;
    }

    & p{
        margin: 0 !important;
        line-height: 1.3;
    }
}   


