@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;

.block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 2rem; 

    @media screen and (max-width: breakpoints.$screen-sm) {
        order: 3;
        padding: 0 2vw;
        flex: 1 1 auto;
    }

    label{
        color: #555;
    }
}

// search
.group {
    width: 90% !important;
    // max-width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0;
    // border: 1px solid #ccc;
    // border-radius: var(--button-border-radius;
    position: relative;
    flex: 1 0 0;
    margin: 0 3rem;

    @media screen and (min-width: breakpoints.$screen-xl) {
        margin: 0;
        width: 100% !important;
    }

    @media screen and (max-width: breakpoints.$screen-xl) {
        margin: 0 2rem;
    }

    @media screen and (max-width: breakpoints.$screen-lg) {
        margin: 0;
        width: 75% !important;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        margin: 0;
        order: 5;
        width: 100% !important;
        flex: 1 1 auto;
    }

    @media screen and (max-width: breakpoints.$screen-sm) and (orientation: landscape) {
        width: 50%;
        margin: 0 .75rem;
    }



    
}

.mini{
    @media screen and (max-width: breakpoints.$screen-md) {
        width: 70%;
        flex: 1 0 auto;
    }

    @media screen and (max-width: breakpoints.$screen-xs) {
        margin: 0;
        // flex: 0;
        order: inherit;
    }
}


.control {
    flex: 1 1 auto;
    // height: 40px;
    border: none;
    border-radius: 0;
    padding: 0 2rem 0 0;
    outline-color: transparent;
    background-color: #fff;

    & input{
        border: 1px solid #ccc;
        height: 100%;
        @extend %search-cancel-button;
        border-radius: var(--button-border-radius);
    }

    @media screen and (max-width: breakpoints.$screen-lg) {
        padding: 0;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        padding: 0;
        width: 38vw;
    }

    
}

.btn {
    border-radius: var(--button-border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 50px;
    background-color: transparent;
    padding-top: 3px;

    @media screen and (max-width: breakpoints.$screen-sm) {
        padding-left: 1rem;
    }
}

.icon {
    fill: #ccc;
}



.typeahead {
    position: absolute;
    background-color: white;
    top: 4rem;
    left: 3rem;
    width: 90%;
    z-index: 999999;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);

    & [class*="Results_block"]{
        display: none;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        width: 100%;
        left: 0;
    }
}
