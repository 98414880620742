@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    flex-wrap: wrap;
    margin: 4rem 0 0 0;
}

.group{
    width: 50%;
    padding: 4rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    position: relative;

    &:nth-last-child(-n+2){
        border-bottom: none;
    }

    &:nth-child(odd){
        &::after{
            content: "";
            width: 1px;
            height: 80%;
            background-color: #ccc;
            position: absolute;
            right: 0;
            top: 2rem;
        }
    }
}

.term{
    color: var(--indigo)
}

.desc{
    & > p:last-of-type{
        margin-bottom: 0;
    }

    @extend %unordered-list;
}


