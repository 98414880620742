@use 'styles/globals/breakpoints';
@use "styles/globals/variables" as *;
@use "styles/globals/mixins/grid-child-columns" as *;


@mixin gridded($type: grid, $col: 2rem, $gap: $gap) {
	@if ($type == "flex") {
		display: flex;
		flex-wrap: wrap;
		margin-left: -#{$gap};
		> * {
			flex: 1 1 $col;
			margin: 0 0 $gap $gap;
		}
	}
	
	@if ($type == "grid") {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax($col, 1fr));
		grid-gap: $gap;
	}

	@if ($type == "multicol") {
		column-gap: $gap;
		column-width: $col;
	}
}

// Mobile Landscape
@media only screen and (min-width: breakpoints.$screen-xs) {
	@include make-grid-items(xs);
}

// Tablet Portrait
@media only screen and (min-width: breakpoints.$screen-sm) {
	@include make-grid-items(sm);
}

// Tablet Landscape
@media only screen and (min-width: breakpoints.$screen-md) {
	@include make-grid-items(md);
}

// Desktop
@media only screen and (min-width: breakpoints.$screen-lg) {
	@include make-grid-items(lg);
}

// Desktop Wide & HD TV
@media only screen and (min-width: breakpoints.$screen-xl) {
	@include make-grid-items(xl);
}

.row{
	flex-direction: row !important;
}

.wrap{
	flex-wrap: wrap !important;
}
