
%text{
    font-size: 1.3rem;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
}

.block{
    display: flex;
    border-bottom: 1px solid var(--dove) !important;
    list-style: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 2rem 0;
    width: 100%;
    margin-bottom: 0;
    background-color: var(--white);
    cursor: pointer;
    list-style: none;

    &::-webkit-details-marker{
        display: none !important;
    }
}

.name{
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.optionsLength{
    letter-spacing: 0.05rem;
    font-size: 1.1rem;
    font-weight: 600 !important;
    color: #777;
}

.step{
    background-color: var(--ocean);
    color: var(--white) !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 1;
    min-width: 40px;
    height: 40px;
    border-radius: 99em;
    margin: 0 2rem 0 0;
    padding-left: 4px;
}

.stepCallout{
    padding: 0;
    background-color: var(--indigo);
}

.stepCheck{
    background-color: var(--green);
    padding: 0;
}

.stepIcon{
    fill: var(--white);
}

.stepIconError{
    fill: var(--white);
    margin-left: -3px;
    margin-top: -3px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  	transform: translate3d(0, 0, 0);
}

.stepError{
    background-color: var(--terracotta);
}

.label{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    width: 100%;
}

.toggleIcon{
    width: 25px;
    max-width: 25px;
    height: 25px;
    transform: rotate(90deg);
    transition: transform 200ms ease-in-out;
    flex: 1 0 auto;
}

.iconRotate{
    transition: transform 200ms ease-in-out;
    transform: rotate(45deg);
}

.text{
    display: flex;
    flex-direction: column;
}

.extras{
    line-height: 1;
}

.extrasName{
    @extend %text;
    color: #aaa;
    margin-right: 1rem;
}

.extrasStandard{
    text-transform: none;
}

.optionSelections{
    display: flex;
    align-items: center;
}

.image{
    margin-right: .5rem
}

.selected{
    @extend %text;
    color: #777;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.05rem;
    font-weight: 600;
}

.error{
    font-size: 1.6rem;
    display: flex;
    align-items: center;
}

.controlGroup{
    display: flex;
    width: 100%;
    padding-left: 1rem;
    background-color: var(--mist);
}

.control{
    width: 25%;
    flex: none;
    display: flex;
    align-content: center;
}


@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	  }
	  
	  20%, 80% {
		transform: translate3d(2px, 0, 0);
	  }
	
	  30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	  }
	
	  40%, 60% {
		transform: translate3d(4px, 0, 0);
	  }
}
