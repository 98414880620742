@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


// main nav items
.mainNav{
    width: 100%;
    border-top: 1px solid var(--mist);
    border-bottom: 1px solid var(--mist);
    position: relative;

    @media screen and (max-width: breakpoints.$screen-sm){
        position: fixed;
        width: 100vw;
        height: 100vw;
        display: none;
    }
}

.mainFlyout{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
}

.closeBtn{
    position: absolute;
    opacity: 0;
    right: 0;
    background-color: transparent;
    z-index: 999;

    & svg{
        fill: #dddddd;
    }
}

.seeMore{
    position: absolute;
    right: 16px;
    font-size: 1.4rem
}


.list{
    @extend %flex-full-width;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    padding: 0 var(--header-padding);
    list-style: none;
    // max-width: 1400px;
}

.listFlyout{
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin: 0;

    @media screen and (max-width: breakpoints.$screen-lg) and (orientation: portrait){
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
    }

}


.activeMainItem{
    background-color: var(--ice50) !important;
}


.megaItem{
    & .link{
        &:hover{
            @extend .activeMainItem;
        }
    }
}

.item{
    display: flex;
    border-bottom: 3px solid transparent;
    cursor: pointer !important;


    & .closeBtn{
        opacity: 1;
    }

    &:hover{
        border-bottom-color: var(--ocean);
    }

    &:first-of-type{
        margin-left: -2.5rem;
    }

    &:last-of-type{
        margin-right: -2.5rem;
    }
}

.itemFlyout{
    background-color: #ddd;
    border-bottom: 2px solid #fff;
    flex-direction: column;
    display: flex;
    flex: 1 0 auto;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        align-content: center;
        justify-content: center;
    }
}


.link{
    display: flex;
    padding: 1.5rem 2rem;
    justify-content: center;
    line-height: 1;
    color: inherit;
    @include font.dynamic-font(14, 18);
    position: relative;

    @media screen and (max-width: breakpoints.$screen-xl){
        padding: 1.5rem 1.5rem 1.2rem 1.5rem;
        text-align: center;
    }

    @media screen and (max-width: 1310px){
        padding: 1.5rem 1rem;
    }

    &:hover{
        color: inherit;
        text-decoration: none;
        cursor: pointer !important;
    }
}

// mobile
.linkFlyout{
    width: 100%;
    text-align: left;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    line-height: 1;
    color: inherit;
    cursor: pointer;
    
    // mobile active state
    &:hover{
        color: inherit;
        text-decoration: none;
    }

    &::after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 11h-6v-6c0-0.6-0.4-1-1-1s-1 0.4-1 1v6h-6c-0.6 0-1 0.4-1 1s0.4 1 1 1h6v6c0 0.6 0.4 1 1 1s1-0.4 1-1v-6h6c0.6 0 1-0.4 1-1s-0.4-1-1-1z'%3E%3C/path%3E%3C/svg%3E");            
        width: 24px;
        height: 24px;
        display: block;
        margin-left: 10px;
    }

    @media screen and (max-width: breakpoints.$screen-lg){
        @include font.dynamic-font(18, 28);
        background-color: #ddd;
    }


}


.offers{
    &::after{
        display: none;
    }
}


.active{
    &::after{
        display: block;
        content: "";
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M18.984 12.984h-13.969v-1.969h13.969v1.969z'%3E%3C/path%3E%3C/svg%3E%0A");
    }
}


