@use 'styles/globals/breakpoints';


.block{
    display: flex;
    margin: 0;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
        //margin: 5rem 0;
    }
}

.image{
    object-fit: contain;
    height: auto;    
    width: 100%;
}

.item{
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    width: 50%;

    &:last-of-type{
        & .calloutBlock {
            order: 2;
        }
    }

    &:hover{
        text-decoration: none !important;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
    }
}

.calloutBlock{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--charcoal);

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
    }
}

.title{
    margin: 0 0 2rem 0;
    line-height: 1;  
    min-height: 50px;
    display: flex;
    align-items: flex-end;

    &:hover{
        color: var(--link_color);
    }
}

.subTitle{
    margin: 0 2rem 0 0;
    border-bottom: none;
    text-decoration: underline;
}

.icon{
    width: 50px;
    fill: inherit;
    stroke: inherit;
}

.imgCntr{
    position: relative;
    width: 100%;
}


