


.block{
    margin: 8rem 0;
    background-color: var(--mist);
    padding: 4rem 0
}

.heading{
    margin-top: -6rem
}

.list{
    position: relative;
    margin: 0;
    padding-left: 4rem;

    &::before{
        content: "";
        position: absolute;
        height: 100%;
        border-left: 5px solid var(--ice);
        left: 0;
    }
}

.item{
    margin-bottom: 1rem;
}
