
.block{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    grid-row: span 1; 
}

.item{
    display: flex;
    align-items: center;
    width: 50%;
    padding: 1rem 2rem 1rem 0;
}

.icon{
    height: 51px;
}

.sunbrella{
    height: 35px;
    fill: #db6015;
}

.text{
    width: 100%;
    padding-left: 1rem;
    text-transform: capitalize;
}
