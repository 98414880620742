@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    position: absolute;
    width: 100%;
    top: 46px;
    left: 0;
    z-index: 9999;
    background-color: var(--white);
    box-shadow: 3px 9px 11px 0px rgba(0, 0, 0, .25);
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    transition: var(--easeInOutBack);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    margin: 0;

    & [class^="DesktopFlyout_columns"]{
        opacity: 0;
    }
}

.viewCntr{
    width: 100%;
}

.visible{
    opacity: 1;
    visibility: visible;

    & [class^="DesktopFlyout_columns"]{
        opacity: 1;
    }
}

.default{
    @extend %max-flyout-height;
}

.short{
    @extend %min-flyout-height;
}

.wee{
    @extend %wee-flyout-height;
}
