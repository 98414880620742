@use 'styles/globals/breakpoints';

.content {
    padding: 8rem 0;
}

.mainHeading {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.headingCategory {
    font-size: initial;
}

.hero {
    object-fit: cover;
    height: auto;
}

.resultsCount {
    text-align: left;
    font-size: 1.35em;
    font-weight: bold;
    // padding-top: 1rem;
}

.sortBy {
    text-align: right;
    display: flex;
    margin-top: -4rem;
}

.topPagination{
    @media only screen and (max-width: breakpoints.$screen-md) {
        display: none;
    }
}

.resultsHeadingRow{
    @media only screen and (max-width: breakpoints.$screen-lg) {
        grid-column-end: 11 !important;
    }
}


.resultsHeading{
    margin: 0;
    justify-content: center;
    
    @media only screen and (max-width: breakpoints.$screen-lg) {
        text-align: center;
        width: 100%;
    }
}

.topResults {
    padding: 0 0 4rem 0;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0 2rem;
        width: 100%;
        text-align: center;
        margin-bottom: 4rem;
    }
}

.podResults {
    @media only screen and (max-width: breakpoints.$screen-md) {
        justify-content: center;
        align-items: center;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        width: 100% !important;
    }
}

.slideButton {
    display: none;
}

.displayTags {
    display: inline-block;

    @media only screen and (max-width: breakpoints.$screen-md) {
        width: 100%;
    }
}

.mobileDisplayTags {
    display: none;
}


@media only screen and (max-width: breakpoints.$screen-lg) {
    .mobileDisplayTags {
        display: block;
    }

    .slideButton {
        display: flex;
        background-color: var(--ice50);
        border-radius: var(--button-border-radius);
        width: auto;
        margin: 0 1rem 2rem 1rem;
        padding: 1rem 2rem;
        font-size: 1.8rem;
        border: 1px solid var(--ice);
        color: var(--ocean);
        justify-content: center;

        & svg{
            fill: var(--ocean);
        }
    }

    .sortBy {
        display: none;
    }

    .topResults {
        padding-left: 0px;
    }

    .resultsCount {
        padding-top: 1rem;
        margin-left: 1rem;
    }

}

