
.block{
    display: flex;
    align-items: center;
    margin: 10rem 0;
    padding: 4rem 0;
    position: relative;
}

.figure{
    width: 50%;
}

.content{
    width: 50%;
    padding-left: 4rem;
}

.background{
    position: absolute;
    width: 200vw;
    top: 0;
    left: -100vw;
    z-index: -1;
    height: 100%;
}

