@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


// chat
.block{
    width: 30%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex: 1 0 auto;

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 20%;
        justify-content: flex-start;
    }
}

.btn{
    background: transparent;
    display: flex;
    align-content: center;
    align-items: center;
    height: 36px;
    justify-content: center;
}

.fullSize{
    @media screen and (max-width: breakpoints.$screen-sm){
        display: none;
    }
}

.icon{
    width: 100%;
    display: none;
    overflow: visible;

    @media screen and (max-width: breakpoints.$screen-sm){
        width: auto;
    }
}

.iconShow{
    display: block;
}



.callText{
    margin-left: 1rem;
    line-height: 1.2;   
    @include font.dynamic-font(13, 16);
    align-content: flex-start; 
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    color: #aaa;

    @media screen and (max-width: breakpoints.$screen-sm){
        display: none;

        &__Chat{
            display: block;
        }
    }
}

.statusDotCntr{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 3px;
}

.statusDot{
    width: 15px;
    height: 15px;
    fill: #aaa;
}

.statusDotText{
    padding-left: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #aaa;
}

.bubbleIcon{
    fill: #aaa;
}

.statusOnline{
    fill: var(--ocean);
}

.statusOnlineText{
    color: var(--ocean);
}

