@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;


.button{
    display: flex;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    align-self: left;
    background-color: transparent;
    padding: 1rem;
    position: relative;
    margin: 0;
    outline-color: transparent;
    color: inherit;
    appearance: none;
    -webkit-appearance: none;

    [type='button']{
        border: none !important;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 1rem;
    }

    &:hover{
        color: inherit;
        text-decoration: none;
    }

    &__flyout{
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        padding: 2rem;
        color: inherit;
        align-content: center;
        align-items: center;
    }

    &__cart{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }
}


.utils{
    justify-content: space-between;
    flex: 1 0;
    max-width: 500px;
}

.icon{
    width: 24px;
    height: 24px;
}

.chat{
    background: transparent;
    align-content: center;
    align-items: center;
    display: none;

    @media screen and (max-width: breakpoints.$screen-lg){
        display: flex;
    }
}

.text{
    margin-left: 1rem;
    font-size: 1.5rem;
    line-height: 1;
    white-space: nowrap;

    @media screen and (max-width: breakpoints.$screen-lg){
        display: none;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        // display: block;
        font-size: 1.8rem;
    }

    &__flyout{
        display: block;
        font-size: 2.1rem;
        color: inherit;
        margin-left: 2rem;
    }

    &__more{
        display: block;
    }

}

 // utility menu
 .buttonCntr{
    display: flex;
    align-content: center;
    margin: 0;

    &__flyout{
        flex-direction: column;
        flex: 1 0 auto;
    }
}

.buttonItem{
    position: relative;
    display: flex;
    align-content: center;

    &:hover{
        & .header__utilMenu{
            display: flex;
        }
    }

    &__flyout{
        width: 100%;
        border-bottom: 2px solid #eee;
    }
}


.utilMenu{
    padding: 1rem 0 1rem 0;
    position: absolute;
    background: #ffffff;
    flex-direction: column;
    border: 1px solid #ddd;
    z-index: 999;
    top: 52px;
    right: -25px;
    width: 350px;
    border-radius: 4px;
    box-shadow: 3px 9px 11px 0px rgba(0, 0, 0, 0.10);

    @media screen and (min-width: breakpoints.$screen-sm) and (max-width: breakpoints.$screen-md){
        right: 50%;
        margin-right: -50px;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        margin: 0px;
        right: -70px;
    }
}


.utilMenuItem{
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    
    &:last-of-type{
        border-bottom: none
    }
}

.utilMenuLink{
    padding: 20px;
    line-height: 1;
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: capitalize;
    color: inherit;

    &:hover{
        text-decoration: underline;
        color: inherit;
    }

    & .header__utilityIcon{
        margin-right: 10px
    }
}

.utilMenuBtn{
    // @extend .button;
    // @extend .button--small;
    margin: 15px 15px 0 15px !important;

    &__green{
        // @extend .button-primary--green;

        &:hover{
            color: #fff !important;
        }
    }

    &__darkTrans{
        // @extend .button-secondary--darkTrans;
        margin-bottom: 15px !important;
    }
}


.utilityBtnContent{
    &__flyout{
        display: flex;
        align-items: center;
        align-content: center;
    }
}


.flyout{
    font-size: 1.6rem;
    position: relative;
}

.moreText{
    display: block !important;
}

