@use 'styles/globals/breakpoints';


.block{
    width: 100%;
    height: 100%;
    background-color: var(--dove);

    @media only screen and (max-width: breakpoints.$screen-md ) {
        height: 600px;
    }
}


