@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;


.block{
    // min-height: 205px;
    display: block !important;

    @media print{
        display: none !important;
    }
}

.hide{
    display: none !important;
}

.cntr{
    @extend %flex-full-width;
    flex-direction: column;
    position: relative;
    // height: 160px;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;   
    }
}


.auxCntr{
    @extend %flex-full-width;
    flex-direction: row;
    padding: 1rem var(--header-padding) 0 var(--header-padding);

    @media screen and (max-width: breakpoints.$screen-md){
       display: none; 
    }
}

.wrapper{
    @extend %flex-full-width;
    flex-direction: column;
    max-width: var(--standard-width);
}


// center main body
.bodyWrapper{
    @extend %flex-full-width;
    flex-direction: column;
    // height: 125px;
    justify-content: flex-end;
    flex: 1 0 auto;

    @media screen and (max-width: breakpoints.$screen-xl){
        height: auto;
    }
}

.bodyCntr{
    @extend %flex-full-width;
    flex-direction: column;
    width: 100vw;
    background: #ffff;

    &__fixed{
        left: 0;
        z-index: 999;
        box-shadow: 3px 9px 11px 0px rgba(0, 0, 0, 0.10);

        & [class*="Header_body"]{
            justify-content: space-between;
            width: 100%;
        }
    }
}

.body{
    @extend %flex-full-width;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0 var(--header-padding);
    justify-content: space-between;

    @media screen and (max-width: breakpoints.$screen-lg) {
        justify-content: flex-start;
        padding: 1rem 2rem;
    }
    
    @media screen and (max-width: breakpoints.$screen-md) {
        justify-content: center;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        flex-wrap: wrap;
    }
}

.wrap{
    flex-wrap: wrap;
}

.logoBlock{
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    
    @media screen and (max-width: breakpoints.$screen-lg) {
        margin-right: 2rem;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        margin: 0;
    }
}


// utils
.bar{
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 30%;

    @media screen and (min-width: breakpoints.$screen-xl){
        // width: 70%;
    }    

    @media screen and (max-width: breakpoints.$screen-md){
        justify-content: space-between;
        width: auto;
        height: auto;
        // width: 100%;
    }

    &__logoChat{
        display: flex;
    }
}

.top{
    display: flex;
    width: 100%;
    padding: 0 4vw;
    align-items: center;
}

.mobileBar{
    @media screen and (min-width: breakpoints.$screen-xl){
        width: 100%;

        & [class*="HeaderLogo_logo"]{
            width: 25%;
        }

        & + [class*="HeaderSearch_block"]{
            padding: 1rem 0 0 0;
        }
    }
}

.immobileAux{
    order: 3;
    display: flex;
    margin-left: 2rem;
}

.topImmobile{
    padding-right: 2rem;
    width: auto;
}

