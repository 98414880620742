@use 'styles/globals/breakpoints';

.block{
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: var(--mist);
    padding: 8rem 0;
    width: 100%;
    // margin-bottom: 10rem;
}

.imgCntr{
    width: 100%;
    height: 100%;
}


.image{
    object-fit: contain;
    width: 100%;
    height: auto;
}

.content{
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
    }
}

.text{
    grid-column: 3 / 9;
    grid-row: 1;
}

.cntr{
    display: flex;
    flex-direction: column;
    padding-left: 6rem;
    flex: 1 0 50%;
    align-content: center;
    justify-content: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 4rem 2rem;
    }
}

.imgCntr{
    display: flex;
    flex: 1 0 50%;

    & img{
        object-fit: responsive !important;
    }
}

