
@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.text{
    margin-bottom: 1rem;
    padding: 4rem;

    & p:last-of-type{
        margin: 0
    }

    & a > strong{
        display: inline-flex;
        background-color: var(--ocean);
        flex: 0 0 auto;
        color: var(--white);
        font-weight: normal;
        padding: 1.7rem calc(1vw + 17px);
        text-transform: capitalize;    
        border-radius: var(--button-border-radius);
        @include font.dynamic-font(16, 24);
        text-decoration: none;
        width: auto;
        @extend %button-animate;
    }
}

.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.secondaryImage{
    display: block;

    @media only screen and ( min-width: breakpoints.$screen-sm ) and ( max-width: breakpoints.$screen-xl) {
       display: none;
    }
}

.recommCntr{
    justify-content: center;
    padding-left: 2rem;
}

.placement{
    grid-template-rows: 1fr;
    
    @media only screen and ( max-width: breakpoints.$screen-md ) {
       padding-bottom: 2rem;
    }
}

.content{
    order: 1;
    display: flex;
    flex-direction: row;

    @media only screen and ( max-width: breakpoints.$screen-md ) {
        padding: 0 2rem;
    }
}

.textCntr{
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and ( max-width: breakpoints.$screen-md ) {
        padding: 0 0 1rem 0
    }
}

.textInline{
    flex-direction: row;
    padding: 4rem;

    @media only screen and ( max-width: breakpoints.$screen-md ) {
        flex-direction: column;
    }

    & .text{
        padding: 0 4rem 0 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and ( max-width: breakpoints.$screen-md ) {
            padding: 0;
            margin-bottom: 2rem;
        }
    }
}

