@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 4rem 30rem 4rem;
    margin: 10rem 0 50rem 0;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin-bottom: 0;
        padding: 6rem 0 0 0;
    }
}

.blockExpanded{
    margin-bottom: 30rem;
}


.header{
    text-align: center;
}

.accordion{
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: hidden;
    position: absolute;
    padding: 0 4rem;
    top: 17rem;
    left: 0;
    height: 600px; 
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
        position: relative;
        padding: 0 2rem;
        top: 0;
        background-color: #fff;
    }

}




.column{
    flex: initial;
    width: calc(100%/5);
    text-align: center;
    color: #fff;
    text-decoration: none;
    vertical-align: bottom;
    overflow: hidden;
    position: relative;
    transition: all .3s cubic-bezier(0.83, 0, 0.17, 1);
    cursor: pointer;
    height: 600px;
    background-size: auto 600px;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    transition: opacity .2s ease 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        height: 10%;
        background-size: cover;
        background-position: center center;
        border-bottom: 2px solid #fff;
    }

    &:hover{
        width: 70%;

        @media only screen and (max-width: breakpoints.$screen-md ) {
            width: 100%;
            height: 70%;
        }

        & .text{
            opacity: 1;
            transition: opacity .3s cubic-bezier(0.83, 0, 0.17, 1) .3s;
        }
    }
}



.text{
    align-self: flex-end;
    margin: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    text-shadow: var(--box-shadow);
    background-color: var(--indigo);
    width: 100%;
    padding: 4rem 2rem;

    & *{
        color: var(--white);
    }
}




