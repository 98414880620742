@use 'styles/globals/breakpoints';
@use "styles/globals/gridUtils.module.scss" as *;


.container {
	display: grid;
	max-width: var(--standard-width);
	grid-template-columns: repeat(10, minmax(0, 1fr));
	width: 100%;
	grid-template-rows: auto 1fr;

	@media only screen and ( max-width: breakpoints.$screen-md ) {
		// grid-template-columns: repeat(1, minmax(0, 1fr));
		display: flex;
		flex-direction: column;
	}

	@media only print{
		display: grid !important;
	}
}

.alignLeft{
	margin-left: -2rem;
}

@media print {
    .container{
        display: flex;
    }
}
