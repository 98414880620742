
.block{
    display: flex;
    align-content: center;
    padding-bottom: 1rem;
    line-height: 1;
    position: relative;
    width: 100%;
    align-items: center;

    &:has([class^="affirm-ala-price"]) .help{
        display: block !important;
    }
}

.help{
    display: none;
    margin-right: .5rem;
}

