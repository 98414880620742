@use 'styles/globals/breakpoints';


.block{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 4rem;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 2rem;
}

.logo{
    margin-bottom: 2rem;
}

.image{
    max-width: 360px !important;
    object-fit: contain;
    height: auto;
        @media only screen and (max-width: breakpoints.$screen-sm ) {
                max-width: 90% !important;
            }
}

.byline{
    width: 100%;
    font-size: 2.1rem;
    text-align: center;
    margin-bottom: 0;
}

