
.block{
    display: flex;
    width: 100%;
    padding: 10px 20px;
    margin: 10px auto 20px auto;
    border-radius: var(--border-radius);
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    line-height: 1.4;

    & > p{
        margin: 0;
        flex: 1;
    }

    & > svg {
        width: 25px;
        height: 25px;
        margin-right: 15px;
        flex: 0 0 25px;
    }
}


.color{
    &__ice{
        background-color: var(--ice);
        color: var(--charcoal);

        & > svg{
            fill: var(--charcoal);
        }
    }

    &__dove{
        background-color: var(--dove);
        color: var(--charcoal);

        & > svg{
            fill: var(--charcoal);
        }
    }
    
    &__green{
        background-color: var(--pistachio);
        color: var(--charcoal);

        & > svg{
            fill: var(--charcoal);
        }
    }
}


