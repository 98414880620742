@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    @media only screen and (max-width: breakpoints.$screen-md) {    
        padding: 0 2rem
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
        flex-direction: column;
    }
}

.col{
    width: 33.3%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;;

    & img{
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        width: 100%;
    }
}

.logo{
    width: 100%;
}

.mid{
    padding: 3rem 5rem;
    margin: 0 3rem;
    background-color: var(--ice);

    @media only screen and (max-width: breakpoints.$screen-lg) {
        padding: 2rem;
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
        margin: 3rem 0;
    }
}

.text{
    margin: 4rem 0;
    text-align: center;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        display: none;
    }
}


